"use client";

import React, { createContext, useContext, useEffect, useState } from "react";

import { PageType, StrapiHeaderNotice } from "@/data/strapi";
import { fetchStrapi } from "@/data/strapi-fetch";

interface LayoutContext {
  showCategories: boolean;
  setShowCategories: (show: boolean) => void;
  headerNotices: StrapiHeaderNotice[];
  setHeaderNotices: (notices: StrapiHeaderNotice[]) => void;
  pageType: PageType;
  setPageType: (type: PageType) => void;
}

export const LayoutContext = createContext<LayoutContext>({
  showCategories: true,
  setShowCategories: () => {},
  headerNotices: [],
  setHeaderNotices: () => {},
  pageType: PageType.Home,
  setPageType: () => {},
});

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [showCategories, setShowCategories] = useState(true);
  const [headerNotices, setHeaderNotices] = useState<StrapiHeaderNotice[]>([]);
  const [pageType, setPageType] = useState<PageType>(PageType.Home);

  useEffect(() => {
    const fetchHeaderNotices = async () => {
      const nowString = new Date().toISOString();
      const response = await fetchStrapi(
        "/header-stoerers",
        {
          $and: [
            { $or: [{ startingAt: { $null: true } }, { startingAt: { $lt: nowString } }] },
            { $or: [{ endingAt: { $null: true } }, { endingAt: { $gt: nowString } }] },
          ],
        },
        false,
      ).catch(() => {
        return { data: [] };
      });
      setHeaderNotices(response.data ?? []);
    };

    fetchHeaderNotices();
  }, []); // Ensure this useEffect runs only once on mount

  return (
    <LayoutContext.Provider
      value={{
        showCategories,
        setShowCategories,
        headerNotices: headerNotices,
        setHeaderNotices: setHeaderNotices,
        pageType: pageType || PageType.Home,
        setPageType: setPageType,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);

  if (context === null) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }

  return context;
};
