import clsx from "clsx";
import Link from "next/link";
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

type ButtonVariant = "primary" | "active" | "outline" | "dark";

export type ButtonProps = {
  variant?: ButtonVariant;
  rounded?: boolean;
  href?: string;
  padding?: string;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = ({ href, children, className, variant = "primary", rounded, padding = "default", ...props }: ButtonProps) => {
  const button = (
    <button
      {...props}
      className={clsx(
        "focus:ring-0 focus:outline-none py-1 cursor-pointer break-all",
        {
          "bg-gray-200 hover:bg-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed": variant === "primary",
          "bg-ch21-color hover:bg-ch21-color-hover disabled:bg-pink-700 disabled:cursor-not-allowed text-white": variant === "active",
          "border border-gray-600 hover:bg-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed pl-5 pr-5 bg-white": variant === "outline",
          "bg-zinc-500 hover:bg-pink-600 text-white disabled:bg-pink-600 disabled:cursor-not-allowed pl-10 pr-10": variant === "dark",
          "rounded-full": rounded,
          "pl-4 pr-4": padding === "default",
          "!bg-ch21-oos-gray": props.disabled === true,
        },
        padding !== "default" && padding,
        className,
      )}
    >
      {children}
    </button>
  );
  if (href) {
    return <Link href={href}>{button}</Link>;
  }
  return button;
};

export default Button;
