import fetchAPI, { fetchWebtools } from "@/utils/fetch-strapi";

export const fetchStrapi = (path: string, filters: unknown, webtools = false) => {
  const urlParamsObject = {
    populate: "deep,4",
    nested: true,
    filters: filters,
  };
  return webtools ? fetchWebtools(path, urlParamsObject) : fetchAPI(path, urlParamsObject);
};
