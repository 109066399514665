"use client";

import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

import { CloseLineIcon } from "@/components/Icon/Close/CloseLineIcon";
import { FlyoutContext } from "@/lib/contexts/flyout-context";
import { useLayout } from "@/lib/contexts/layout-context";

interface FlyoutProps extends React.PropsWithChildren {
  open: boolean;
  onClose?: () => void;
  className?: string;
  right?: boolean;
  center?: boolean;
  button?: React.ReactNode;
}

export const FlyoutPortal = () => {
  const { setPortal } = useContext(FlyoutContext);

  return <div ref={(node) => setPortal(node)} data-id="flyout-portal" />;
};

const calculateHeaderHeight = () => {
  const header = document.querySelector("header");
  const notices = document.querySelectorAll(".headNotice");
  if (!header) {
    return 0;
  }

  const calculateVisibleHeight = (element: Element) => {
    const rect = element.getBoundingClientRect();
    return Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0));
  };

  let totalHeight = calculateVisibleHeight(header);

  notices.forEach((notice) => {
    totalHeight += calculateVisibleHeight(notice);
  });

  return totalHeight;
};

export const Flyout = ({ children, open, className, right, center, button, onClose }: FlyoutProps) => {
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const { portal } = useContext(FlyoutContext);
  const { headerNotices } = useLayout();
  const onCloseClick = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (open) {
      setHeaderHeight(calculateHeaderHeight());
    }
  }, [open]);

  useLayoutEffect(() => {
    setHeaderHeight(calculateHeaderHeight());
  }, [headerNotices]);

  const listener = () => {
    if (open) {
      portal?.focus({ preventScroll: false });
    }

    setHeaderHeight(calculateHeaderHeight());
  };

  const headerHeightEvents = () => {
    window.addEventListener("resize", listener, false);
    window.addEventListener("scroll", listener, false);
  };

  const clearHeaderHeightEvents = () => {
    window.removeEventListener("resize", listener, false);
    window.removeEventListener("scroll", listener, false);
  };

  useEffect(() => {
    headerHeightEvents();
    return () => clearHeaderHeightEvents();
  });

  if (!portal) {
    return null;
  }

  return createPortal(
    <div className={clsx("fixed z-30 inset-0 flex flex-col", { "items-end": right }, !open ? "hidden" : "block")} style={{ paddingTop: headerHeight }}>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={onCloseClick} />
      </div>
      <div
        className={clsx(`w-full sm:w-4/6 md:w-3/6 lg:w-2/6 transform transition-all flex flex-row flex-1`, {
          "flex-row-reverse": right,
          "m-auto md:w-4/6 lg:w-4/6": center,
        })}
      >
        <div className="flex w-full flex-1 flex-col overflow-hidden">
          <div
            className={clsx("text-left shadow-xl overflow-x-hidden overflow-y-auto flex-auto basis-0", { "bg-white": !center })}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className={clsx("p-4", { "bg-white": !center }, className)}>{children}</div>
          </div>
          {button && (
            <div className={clsx("w-full p-4 flex-0", { "bg-white": !center })} style={{ boxShadow: " 0 -10px 20px -5px rgba(115,115,115,0.25)" }}>
              {button}
            </div>
          )}
        </div>

        <div className="flex-0" onClick={onCloseClick}>
          <div className={`relative mt-4 mx-2 cursor-pointer z-10`} onClick={onCloseClick}>
            <CloseLineIcon fill={"#fff"} width={40} onClick={onCloseClick} />
          </div>
        </div>
      </div>
    </div>,
    portal,
  );
};
