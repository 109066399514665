import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { TestInterface } from "@/interfaces/Test/TestInterface";

interface CollapseInterface extends TestInterface {
  id?: string;
  onClick?: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  enabled?: boolean;
  customStyle?: string;
  customContentStyle?: string;
  link?: string | boolean;
  collapsable?: boolean;
  disableClickAction?: boolean;
}

interface SubCategoryInterface {
  subCategory: { name: string };
  onClick?: () => void;
  customStyle?: string | boolean;
  className?: string;
  icon?: React.ReactNode;
  link?: string;
  collapsable?: boolean;
}

export const SubCategory = ({ subCategory, onClick, customStyle, className, icon, link }: SubCategoryInterface) => {
  const pathName = usePathname();
  const inPath: boolean = link !== undefined && link !== null && pathName.includes(link);
  return (
    <div className={clsx("p-2 hover:category-bg-gray mb-1 pl-8", inPath && "category-bg-gray", !inPath && "bg-white", className)}>
      {link ? (
        <Link href={link} onClick={onClick}>
          {icon}
          <p className={`text-black ml-5 ${customStyle}`}>{subCategory.name}</p>
        </Link>
      ) : (
        <span onClick={onClick} className={clsx({ "cursor-pointer": "undefined" !== typeof onClick })}>
          {icon}
          <p className={`text-black ml-5 ${customStyle}`}>{subCategory.name}</p>
        </span>
      )}
    </div>
  );
};

export const Collapse = ({
  id,
  onClick,
  title,
  children,
  enabled,
  customStyle,
  customContentStyle,
  link,
  testId,
  collapsable,
  disableClickAction,
}: CollapseInterface) => {
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    if (typeof enabled === "boolean") {
      setCollapse(enabled);
    }
  }, [enabled]);

  const clickAction = () => {
    !collapse && !disableClickAction && setCollapse(!collapse);
    onClick && onClick();
  };

  let content: React.ReactNode = (
    <div className={!collapse && !enabled ? "hidden" : "block"} id={id}>
      <div className={`${!customContentStyle ? "bg-gray-100 border-2 border-black " : customContentStyle} block w-full`}>{children}</div>
    </div>
  );
  if (!!collapsable && link) {
    content = false;
  }

  return (
    <>
      <div
        className={`${
          customStyle?.length ? customStyle : "bg-gray-100 border-2 border-black hover:bg-gray-200 "
        } w-full cursor-pointer py-2 px-5 font-semibold leading-normal text-black transition duration-150 ease-in-out focus:outline-none focus:ring-0`}
        data-te-ripple-color="light"
        data-te-target={`#${id}`}
        aria-controls={id}
        onClick={() => clickAction()}
        data-testid={testId}
      >
        {link ? <Link href={typeof link === "string" ? link : "#!"}>{title}</Link> : title}
      </div>
      {content}
    </>
  );
};

export const RenderArrow = ({ up }: { up: boolean }) => {
  return up ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};
export function DefaultCollapse({ title, ...props }: CollapseInterface) {
  const [open, setOpen] = useState(false);
  return (
    <Collapse
      onClick={() => setOpen(!open)}
      enabled={open}
      title={
        <>
          <span>{title}</span>
          <RenderArrow up={open} />
        </>
      }
      {...props}
    />
  );
}
